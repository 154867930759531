import { useEffect, useState } from "react";
import "../../css/GameRoom.css"
import GameCard from "./GameCard";
import { useNavigate } from "react-router-dom";

const GameLoader = ({allGamesArray, handleCardClick, clearData}) => {

    const [searchText, setSearchText] = useState("");
    const [gameType, setGameType] = useState("");

    const navigate = useNavigate();

    useEffect(()=>{
        clearData();
    },[])

    const filterGames = () => {

        let filteredArray = allGamesArray;

        if(searchText != ""){
            filteredArray = allGamesArray.filter(game => 
                game.gameName.toLowerCase().includes(searchText.toLowerCase()) || 
                game.userName.toLowerCase().includes(searchText.toLowerCase())
              );
        }
        else{
            if(gameType != ""){
                filteredArray = allGamesArray.filter(game => 
                    game.gameType.toLowerCase().includes(gameType.toLowerCase())
                  );
            }
        }

        return filteredArray;
    }
      

    const handleInputText = (event) =>{
        setSearchText(event.target.value);
    }

    const handleGameTypeClick = (gameType) =>{
        setGameType(gameType);
    }

    const handleNavigate = (link) =>{
        navigate(link);
    }

    return(
        <div style={{width:"100%", height:"100dvh", position:"relative", overflow:"hidden"}}>
            <img 
                src="/assets/games/gameroom/gr_toppaper.png"
                className="gr-toppaper"
            />

            <img 
                src="/assets/games/gameroom/cardboard.png"
                style={{position:"absolute", top:"0", height: "100%", width:"100%", zIndex:"-10"}}
            />

            <img 
                src="/assets/games/gameroom/littlelot_logo.png"
                style={{position:"absolute", width:"50px", top:"20px", left:"20px", cursor:"pointer"}}
                onClick={()=>handleNavigate("/")}
            />

            <img 
                src="/assets/games/gameroom/gamecreator_logo.png"
                style={{position:"absolute", width:"50px", top:"20px", right:"20px"}}
            />

            <img 
                src="/assets/games/gameroom/gr_star.png"
                style={{position:"absolute", width:"20px", top:"10%", left:"20%", transform:"rotate(12deg)"}}
            />

            <img 
                src="/assets/games/gameroom/gr_star.png"
                style={{position:"absolute", width:"20px", top:"2%", left:"30%", transform:"rotate(30deg)"}}
            />

            <img 
                src="/assets/games/gameroom/gr_star.png"
                style={{position:"absolute", width:"20px", top:"12.5%", left:"70%", transform:"rotate(-5deg)"}}
            />

            <div style={{display:"flex", width:"100%", height:"100%", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", zIndex:"20"}}>
                <div style={{height:"12%", display:"flex", justifyContent:"center", alignItems:"flex-end", paddingBottom:"15px"}}>
                    <img src="/assets/games/gameroom/gameroom_title.png" style={{width:"50%", maxWidth:"400px"}} />
                </div>

                <div style={{height:"40px", display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center", padding:"0px 20px"}}>
                    
                    <div style={{display:"flex",alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                        <div className="center-child" style={{width:"30px", height:"30px", backgroundColor:"black", borderRadius:"50%", margin:"0px 5px"}}>
                            <img src="/assets/icon/gallery_icon_white.png" style={{width:"60%", cursor:"pointer"}} onClick={()=>handleGameTypeClick("")}/>
                        </div>
                        
                        <div style={{display:"flex", flex:"3" ,overflowX:"auto", padding:"0px 10px"}}>
                            <img src="/assets/games/gameroom/jumper_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("jumper")}/>
                            <img src="/assets/games/gameroom/platformer_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("platformer")}/>
                            <img src="/assets/games/gameroom/runner_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("runner")}/>
                            <img src="/assets/games/gameroom/mazer_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("mazer")}/>
                            <img src="/assets/games/gameroom/swimmer_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("swimmer")}/>
                            <img src="/assets/games/gameroom/invader_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("invader")}/>
                            <img src="/assets/games/gameroom/racer_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("racer")}/>
                            <img src="/assets/games/gameroom/popper_icon.png" style={{height:"30px", margin:"0px 2px", cursor:"pointer"}} onClick={()=>handleGameTypeClick("popper")}/>
                        </div>

                        <div style={{display:"flex",position:"relative", flex:"1", alignItems:"center", right:"5px", maxWidth:"25%"}}>
                            <input 
                                type="text" 
                                id="search-input" 
                                placeholder="Search..." 
                                style={{height:"22px", width:"100%", zIndex:"5", marginLeft:"5px", paddingLeft:"5px", borderTopLeftRadius:"15px",  borderBottomLeftRadius:"15px", fontFamily:"Quicksand, sans-serif"}}
                                onChange={handleInputText}
                                value={searchText}
                            />
                            <div style={{position:"absolute", backgroundColor: "black", height:"30px", width:"150%", left:"0", borderTopLeftRadius:"15px",  borderBottomLeftRadius:"15px"}}></div>
                        </div>
                        
                    </div>
                </div>

                <div className="game-container">
                    {allGamesArray && allGamesArray.length > 0 && (
                        <div style={{ height: "100%", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent:"flex-start", overflowY: "auto", paddingBottom:"20px" }}>
                            {filterGames().map(game => (
                                <GameCard 
                                    key={game.userId + "_" + game.gameId} 
                                    game={game}
                                    handleCardClick={handleCardClick}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GameLoader;