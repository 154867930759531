import "../../css/HomePage.css"
import { useState } from "react";

const LittleShop = () => {
    const [charImgData, setCharImgData] = useState({
        imgPath: "",
        imgStyle: "",
        isVisible: false
    });
    const [showModal, setShowModal] = useState({
        show: false,
        content: ""
    });

    const handleDotClick = (dot) => {
        // Logic to open pop-up or navigate to another page based on the dot clicked
        /*console.log(`Dot clicked: ${dot.id}`);
        setShowModal({
            show: true,
            content: dot.id
        })*/
      };

      const handleDotHover = (dot) => {
        setCharImgData({
            imgPath: dot.imgPath,
            imgStyle: dot.imgStyle,
            isVisible: true
        })
      };

      const handleDotLeave = () => {
        setCharImgData({
            imgPath: "",
            imgStyle: "",
            isVisible: false
        })
      };

      const handleCloseModal = () => {
        setShowModal({
            show: false,
            content: ""
        })
      }

    const dotData = [
        {id: "buzz", dotStyle: {top:"15%", left:"35%"}, imgPath: "/assets/home/xray/buzz_xray.png"}, //imgStyle: {top:"10%", left:"38%"}},
        {id: "bumbum", dotStyle: {top:"35%", left:"25%"}, imgPath: "/assets/home/xray/bumbum_xray.png"}, //imgStyle: {top:"33%", left:"28%"}},
        {id: "zinga", dotStyle: {top:"52%", left:"32%"}, imgPath: "/assets/home/xray/zinga_xray.png"}, //imgStyle: {top:"45%", left:"35%"}},
        {id: "tata", dotStyle: {top:"60%", left:"55%"}, imgPath: "/assets/home/xray/tata_xray.png"}, //imgStyle: {top:"54%", left:"35%"}},
        {id: "barry", dotStyle: {top:"35%", left:"55%"}, imgPath: "/assets/home/xray/barry_xray.png"}, //imgStyle: {top:"20%", left:"50%"}},
        {id: "kenny", dotStyle: {top:"30%", left:"80%"}, imgPath: "/assets/home/xray/kenny_xray.png"}, //imgStyle: {top:"15%", left:"70%"}},
        {id: "living", dotStyle: {top:"50%", left:"59%"}, imgPath: "/assets/home/xray/barry_xray.png"}, //imgStyle: {top:"40%", left:"59%"}},
    ]

    return (
        <div className="littleShopContainer">
            <div className="littleShop">
                <img className="shopImage" src="/assets/home/littleshop.png" />
                {dotData.map(dot => (
                    <div 
                        key={dot.id} 
                        className="shopDot" 
                        style={dot.dotStyle} 
                        onClick={()=>handleDotClick(dot)}
                        onMouseEnter={()=>handleDotHover(dot)}
                        onMouseLeave={()=>handleDotLeave()}
                    ></div>
                ))}

                {charImgData.isVisible && (
                    <img className="shopXray" src={charImgData.imgPath}/>
                )}
                                
            </div>
        </div>

    );
};

export default LittleShop;
