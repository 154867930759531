import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import GameRoom from './pages/GameRoom';
import AnalyticsTracker from './components/data/AnalyticsTracker';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gameroom/*" element={<GameRoom />} />
      </Routes>
      <AnalyticsTracker />
    </BrowserRouter>
  );
}

export default App;
