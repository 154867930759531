import "../../css/HomePage.css"
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const NavBar = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePageClick = (page) =>{
    navigate(page);  
  }

  return (
    <div className={`topDiv ${scrollTop > 0 ? "shrink" : ""}`}>
      <div className="navIconContainer">
        <div className="navIconGroup">
        </div>
        <div className="navIconGroup" onClick={() => handlePageClick("/")}>
          <img className="navHomeIcon" src="/assets/navbar/littlelot_icon.png"/>
        </div>
        <div className="navIconGroup" style={{alignItems:"flex-end", justifyContent:"center"}}>
          <img className="navIcon" style={{width:"50%"}} src="/assets/games/gameroom/gamecreator_logo.png" onClick={() => handlePageClick("/gameroom")}/>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
