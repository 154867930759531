import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, collectionGroup } from "firebase/firestore"; 
import { db } from "../firebase";

import JumperGame from "../components/games/JumperGame";
import GameLoader from "../components/games/GameLoader";
import { useEffect, useState } from "react";
import PlatformerGame from "../components/games/PlatformerGame";
import RunnerGame from "../components/games/RunnerGame";
import MazerGame from "../components/games/MazerGame";
import SwimmerGame from "../components/games/SwimmerGame";
import InvaderGame from "../components/games/InvaderGame";
import RacerGame from "../components/games/RacerGame";
import PopperGame from "../components/games/PopperGame";

const GameRoom = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inputId = searchParams.get('gameId');

  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [gameData, setGameData] = useState();
  const [currentGameId, setCurrentGameId] = useState(inputId);
  const [allGamesArray, setAllGamesArray] = useState([]);
  const [reloadGame, setReloadGame] = useState(false);
  const [startedGame, setStartedGame] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if(currentGameId !== null){
        const userId = currentGameId.substring(0, 10);
        const gameId = currentGameId.substring(11, 17);
    
        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);
    
        const gameRef = doc(db, "users", userId, "games", gameId);
        const gameSnap = await getDoc(gameRef);
  
        if(userSnap !== null && gameSnap !== null){
          setUserData(userSnap.data());
          setGameData(gameSnap.data());
  
          if(!startedGame){
            if(gameSnap.data().gameType === "jumper"){
              navigate("/gameroom/jumper?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "platformer" || gameSnap.data().gameType === "platformer_xl"){
              navigate("/gameroom/platformer?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "runner"){
              navigate("/gameroom/runner?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "mazer"){
              navigate("/gameroom/mazer?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "swimmer"){
              navigate("/gameroom/swimmer?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "invader"){
              navigate("/gameroom/invader?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "racer"){
              navigate("/gameroom/racer?gameId=" + currentGameId);
              setStartedGame(true);
            }
            else if(gameSnap.data().gameType === "popper"){
              navigate("/gameroom/popper?gameId=" + currentGameId);
              setStartedGame(true);
            }
          }
        }
      }
      else{
        try {
          const gamesCollectionRef = collectionGroup(db, "games");
          const gamesQuerySnapshot = await getDocs(gamesCollectionRef);
      
          const games = [];
      
          gamesQuerySnapshot.forEach((gameDoc) => {
              const gameData = gameDoc.data();
              if (gameData.publish === true) {
                gameData.userId = gameDoc.ref.parent.parent.id
                games.push(gameData);
              }
          });
      
          setAllGamesArray(games);
          return games;
        } catch (error) {
            console.error("Error fetching published games:", error);
            return [];
        }
      }
    };
  
    fetchData();
  }, [currentGameId, reloadGame, location]);

  const handleCardClick = (gameId) =>{
    setReloadGame(!reloadGame)
    setCurrentGameId(gameId);
  }

  const clearData = () =>{
    setUserData(undefined);
    setGameData(undefined);
    setCurrentGameId(null);
    setStartedGame(false);
  }

  return (
      <Routes>
        <Route path="/" element={
            <GameLoader 
              allGamesArray={allGamesArray}
              handleCardClick={handleCardClick}
              clearData={clearData}
            />} 
        />
        <Route path="/jumper" element={
            <JumperGame
              userData={userData}
              gameData={gameData}
              clearData={clearData}
            />} 
        />
        <Route path="/platformer" element={
          <PlatformerGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />
        <Route path="/runner" element={
          <RunnerGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

        <Route path="/mazer" element={
          <MazerGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

        <Route path="/swimmer" element={
          <SwimmerGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

        <Route path="/invader" element={
          <InvaderGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

        <Route path="/racer" element={
          <RacerGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

        <Route path="/popper" element={
          <PopperGame
            userData={userData}
            gameData={gameData}
            clearData={clearData}
          />} 
        />

      </Routes>
  );
}

export default GameRoom;