import LittleShop from "../components/web/LittleShop";
import NavBar from "../components/web/NavBar";

import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {

  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const navigate = useNavigate();

  const handlePageClick = (page) =>{
    navigate(page);  
  }

  const handleLineClick = () =>{
    window.location.href = "https://lin.ee/EHcxVTI";
  }

  return (
    <div className="home">
      <div className="homeContainer">
          <NavBar />
          <div style={{height:"80dvh", overflow:"hidden"}}>
            <img src="/assets/home/studio/studio_homepage.jpg" style={{width:"100%", height:"100%", objectFit:"cover"}}/>
            <img src="/assets/home/studio/turnyourart.png" style={{position:"absolute", bottom:"19%", left:"50%", width:"70%", maxWidth:"500px", zIndex:"5", objectFit:"contain", transform:"translate(-50%,-50%)"}}/>
            <div style={{
              position:"absolute", 
              width:"150px", 
              height:"40px", 
              bottom:"15%", 
              left:"50%", 
              backgroundColor:"#2D83C5", 
              color:"white", 
              transform: "translate(-50%, -50%)", 
              display:"flex", justifyContent:"center", 
              alignItems:"center", 
              border: "2px solid black", 
              boxShadow:"5px 5px 4px rgba(0, 0, 0, 0.3)", 
              fontFamily:"VT323",
              fontSize:"24px",
              cursor:"pointer"
              }}
              onClick={() => handleLineClick()}
            >BOOK NOW!</div>
          </div>

          <div style={{
            position: "relative", 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            justifyContent: "flex-start",
            textAlign: "center"
          }}>
            <img 
              src="/assets/home/studio/gcw_joystick.png" 
              style={{
                width: "50%", 
                maxWidth: "350px", 
                transform: "rotate(-12deg) translate(0, -10%)"
              }}
            />
            <div style={{ marginTop: "20px", margin:"5%", maxWidth:"500px", lineHeight:"20px", fontFamily:"IBM Plex Sans Thai", whiteSpace:"break-spaces"}}>
              {"อยากมีเกมของตัวเอง ทำได้ง่ายนิดเดียวที่ LittleLot Studio! เพียงแค่วาด สแกน ก็สามารถกลายเป็นเกมที่เอากลับบ้านไปเล่นได้ทุกที่ แถมยังมี product น่ารักๆ ที่สร้างจากภาพวาดของเราได้ด้วยนะ \n\n แล้วถ้าใครอยากให้เกมล้ำยิ่งขึ้น ก็สามารถ 🎵 เลือกเพลง 🎤 อัดเสียง หรือแม้แต่ 💥 เพิ่ม power up ที่จะทำให้เกมสนุกขึ้นไปอีก"}
            </div>
            <div style={{fontSize:"30px", margin:"10px", fontFamily:"VT323"}}>HAPPENING NOW</div>
          </div>

          <div style={{height:"auto", width:"100%", marginBottom:"20px"}}>
            <div className="happening-slider">
              <Slider {...sliderSettings}>
                <div>
                  <div className="happening-box">
                    <div style={{height:"70%", zIndex:"10", position:"relative"}}>
                      <img src="/assets/home/studio/cardboard_info.png" style={{height:"100%", zIndex:"10"}}/>
                      <img src="/assets/home/promo/pixelparty.png" style={{width:"72%", zIndex:"15", position:"absolute", top:"50%", left:"50%", transform:"translate(-45%,-52%)"}}/>
                    </div>
                    <div style={{fontFamily:"VT323", fontSize:"24px", margin:"10px"}}> PIXEL PARTY THEME </div>
                    <div style={{textAlign:"center", width:"300px"}}> Create game elements made from our pixel-inspired templates!</div>
                  </div>
                </div>
                <div>
                  <div className="happening-box">
                    <div style={{height:"70%", zIndex:"10", position:"relative"}}>
                      <img src="/assets/home/studio/cardboard_info.png" style={{height:"100%", zIndex:"10"}}/>
                      <img src="/assets/home/promo/friendpromotion.png" style={{width:"72%", zIndex:"15", position:"absolute", top:"50%", left:"50%", transform:"translate(-45%,-52%)"}}/>
                    </div>                    
                    <div style={{fontFamily:"VT323", fontSize:"24px", margin:"10px"}}> MORE FRIENDS MORE FUN </div>
                    <div style={{textAlign:"center", width:"300px"}}> Get special discounts when inviting your friends to create new games!</div>
                  </div>
                </div>
                <div>
                  <div className="happening-box">
                    <div style={{height:"70%", zIndex:"10", position:"relative"}}>
                      <img src="/assets/home/studio/cardboard_info.png" style={{height:"100%", zIndex:"10"}}/>
                      <img src="/assets/home/promo/rewardcard.jpg" style={{width:"72%", zIndex:"15", position:"absolute", top:"50%", left:"50%", transform:"translate(-45%,-52%)"}}/>
                    </div>                    
                    <div style={{fontFamily:"VT323", fontSize:"24px", margin:"10px"}}> GAME REWARDS CARD </div>
                    <div style={{textAlign:"center", width:"300px"}}> Collect 4 games and get 1 for free!</div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <img src="/assets/home/studio/pixel_button.png" style={{width:"250px", margin:"40px 0px", cursor:"pointer"}}  onClick={()=>handlePageClick("/gameroom")}/>
            <div style={{position:"absolute", fontFamily:"VT323", fontSize:"30px", cursor:"pointer"}} onClick={()=>handlePageClick("/gameroom")}>GAMEROOM</div>
          </div>

          <div style={{height:"auto", backgroundColor:"#F7F5F1"}}>
              <div style={{padding:"50px", fontSize:"24px", fontFamily:"VT323"}}>LITTLELOT STUDIO
                <div style={{whiteSpace:"break-spaces", fontFamily:"Quicksand", fontSize:"14px"}}>{"\nThe Racquet Club \nSukhumvit 49"}</div>
              </div>

              <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src="/assets/home/studio/ll_rq49graphic.png" style={{width:"90%", transform:"translate(0,-20%)", maxWidth:"800px"}}/>
              </div>
          </div>

          <div style={{height:"auto", padding:"50px 0px", width:"100%", marginBottom:"20px"}}>
            <div className="happening-slider">
              <Slider {...sliderSettings}>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned1.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned2.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned3.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned4.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned5.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
                <div>
                  <div className="turnyourart-box">
                    <img src="/assets/home/studio/gridborder.png" style={{height:"100%", zIndex:"15"}}/>
                    <img src="/assets/home/promo/pinned6.png" style={{height:"95%", zIndex:"10", position:"absolute"}}/>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div style={{backgroundColor:"black", display:"flex", justifyContent:"space-between", padding:"30px"}}>
            <div style={{display:"flex", alignItems:"flex-start"}}>
              <img src="/assets/home/studio/littlelot_logo_white.png" style={{width:"150px"}}/>
            </div>
            <div style={{textAlign:"right", fontSize:"12px", color:"white", flex:"1", display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>V2.0.4</div>
          </div>
      </div>
    </div>
  );
};

export default HomePage;
