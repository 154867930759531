// AnalyticsTracker.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const AnalyticsTracker = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [trackingConsent, setTrackingConsent] = useState(null);
  const [showConsentForm, setShowConsentForm] = useState("");

  const location = useLocation();

  useEffect(()=>{
    if (localStorage.getItem("c_data") === null) {
      const dataTracking = {
        fbpixel: "not_allowed",
        ga: "not_allowed"
      }

      localStorage.setItem("c_data", JSON.stringify(dataTracking));
      setTrackingConsent(dataTracking);
    }
    else{
      const cookieAllow = JSON.parse(localStorage.getItem("c_data"));
      if(cookieAllow.fbpixel === "allowed"){
        ReactPixel.init("569675072179637");
      }

      if(cookieAllow.ga === "allowed"){
        ReactGA.initialize("G-QZF32R026C");
      }

      setTrackingConsent(cookieAllow);
    }

    setIsLoading(false);
  },[])

  useEffect(() =>{
    if(isLoading)
      return;

    if(trackingConsent === null || trackingConsent === undefined || trackingConsent.fbpixel !== "allowed" || trackingConsent.ga !== "allowed"){
      if(showConsentForm === ""){
        setTimeout(() => {
          setShowConsentForm("form");
        }, 5000); // 5000 milliseconds = 5 seconds
      }
    } 
    else{
      initPageTracking();
    }
  },[trackingConsent])

  useEffect(() => {
    if(trackingConsent === null || trackingConsent === undefined)
      return;

      initPageTracking();
  }, [location]);

  const initPageTracking = () =>{
    ReactPixel.pageView();
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });
  }

  const handleAcceptClick = () =>{
    const cookieAllow = {
      fbpixel: "allowed",
      ga: "allowed"
    }

    setTrackingConsent(cookieAllow);
    localStorage.setItem("c_data", JSON.stringify(cookieAllow));
    setShowConsentForm("");
  }

  const handleSettingClick = () =>{
    const cookieAllow = {
      fbpixel: "allowed",
      ga: "allowed"
    }

    setTrackingConsent(cookieAllow);

    setShowConsentForm("setting");
  }

  const handleBackClick = () => {
    setShowConsentForm("form");
  }

  const handleAcceptSettingClick = () =>{
    setShowConsentForm("");
    localStorage.setItem("c_data", JSON.stringify(trackingConsent));
  }

  const handleToggleSwitch = (id) => {
    let isOn;
    if(id === "marketing"){
      isOn = !getConsentValue("fbpixel");
      let cookieAllow = {};

      if(isOn){
        cookieAllow = {
          fbpixel: "allowed",
          ga: "allowed"
        }

      }
      else{
        cookieAllow = {
          fbpixel: "not_allowed",
          ga: "not_allowed"
        }
      }

      setTrackingConsent(cookieAllow);
      localStorage.setItem("c_data", JSON.stringify(cookieAllow));
    }
}

  const getConsentValue = (type) =>{
    if(trackingConsent[type] === "allowed"){
      return true;
    }
    else{
      return false;
    }
  }

  if(showConsentForm === "form"){
    return(
      <div 
        className="flex-col"
        style={{
          position:"fixed", 
          bottom:"20px", 
          right:"10px", 
          width:"350px", 
          height:"250px", 
          backgroundColor:"white",
          borderRadius:"15px",
          boxShadow: "5px 7px 9px 2px rgba(0, 0, 0, 0.3)",
          padding:"30px",
          justifyContent:"flex-start",
          alignItems:"center",
          flexWrap:"nowrap",
          zIndex:"250",
          maxWidth:"95%"
      }}
      >
        <div className='title-text' style={{fontSize:"20px", marginBottom:"5px"}}>Your Privacy Matters 🍪</div>
        <div style={{fontSize:"13px", fontWeight:"300"}}>
          We use cookies to enhance your browsing experience and collect design data. To further help us improve our website, kindly grant permission for marketing purposes by allowing us to track your activity through cookies.
        </div>
        <div style={{fontSize:"13px", fontWeight:"300", fontFamily:"IBM Plex Sans Thai", lineHeight:"15px", marginTop:"20px"}}>
          เว็ปไซต์ของเราใช้คุ๊กกี้เพื่อเก็บข้อมูลการออกแบบของคุณ เพื่อประสบการณ์ใช้งานที่ยิ่งขึ้น กรุณาอนุญาตให้เราเก็บข้อมูลของคุณผ่านคุ๊กกี้เพิ่อการพัฒนาเว็ปไซต์และการตลาด
        </div>
        <div style={{display:"flex", flexWrap:"nowrap", padding:"20px 20px", justifyContent:"space-evenly"}}>
          <div className='long-btn' style={{width:"150px", height:"50px", margin:"0px 5px", fontFamily:"Quicksand", fontWeight:"400", fontSize:"16px"}} onClick={()=>handleSettingClick()}>Preferences</div>
          <div className='long-btn tex-corrugated' style={{width:"150px", height:"50px", margin:"0px 5px", fontFamily:"Quicksand",  fontSize:"20px"}} onClick={()=>handleAcceptClick()}>Accept</div>
        </div>
      </div>
    )
  }
  else if(showConsentForm === "setting"){
    return(
      <div 
        className="flex-col"
        style={{
          position:"fixed", 
          bottom:"20px", 
          right:"10px", 
          width:"350px", 
          height:"250px", 
          backgroundColor:"white",
          borderRadius:"15px",
          boxShadow: "5px 7px 9px 2px rgba(0, 0, 0, 0.3)",
          padding:"30px",
          justifyContent:"flex-start",
          alignItems:"center",
          flexWrap:"nowrap",
          zIndex:"250",
          maxWidth:"95%"
      }}
      >
        <div className='title-text' style={{fontSize:"20px", marginBottom:"5px", textAlign:"center"}}>Your Privacy Matters 🍪</div>
        <div className="circle-btn" style={{position:"absolute", margin: "2px", top:"10px", left:"10px"}} onClick={()=>handleBackClick()}>
            <img style={{position:"relative", }}src="/assets/icon/back_icon.png"/>
        </div>

        <div style={{display:"flex", flexDirection:"column", height:"100%", alignContent:"center"}}>
            <div className="switch-container" style={{flexDirection:"row", width:"100%", height:"60px", alignContent:"center", transform:"scale(1) translate(0,0)"}}>
                <div style={{width:"80%"}}>
                  <div style={{fontSize:"14px", width:"100%"}}>Local Storage - Design Data</div>
                  <div style={{fontSize:"10px", width:"100%", fontWeight:"300", marginTop:"0"}}>*required - neccessary cookie</div>
                  <div style={{fontSize:"12px", width:"100%", fontWeight:"300"}}>Used for storing design data</div>
                  <div style={{fontSize:"12px", width:"100%", fontWeight:"300", fontFamily:"IBM Plex Sans Thai"}}>สำหรับเก็บข้อมูลการออกแบบ</div>
                </div>

                <label className="switch" style={{width:"60px", height:"30px"}}>
                    <input type="checkbox" checked={true} disabled={true}/>
                    <span className="slider round" style={{backgroundColor:"#3b3b3b"}}></span>
                </label>
            </div>
            <div className="switch-container" style={{flexDirection:"row", width:"100%", height:"60px", alignContent:"center", transform:"scale(1) translate(0,0)"}}>
                <div style={{width:"80%"}}>
                  <div style={{fontSize:"14px", width:"100%"}}>Marketing Purposes</div>
                  <div style={{fontSize:"12px", width:"100%", fontWeight:"300"}}>(Google Analytics & Facebook Pixel)</div>
                  <div style={{fontSize:"12px", width:"100%", fontWeight:"300", fontFamily:"IBM Plex Sans Thai"}}>สำหรับเก็บข้อมูลการใช้งานและการตลาด</div>
                </div>
                <label className="switch" style={{width:"60px", height:"30px"}}>
                    <input type="checkbox" checked={getConsentValue("fbpixel")} onChange={(e)=>handleToggleSwitch("marketing")}/>
                    <span className="slider round"></span>
                </label>
            </div>

            <div className='align-center' style={{width:"100%"}}>
              <div className='long-btn tex-corrugated' style={{width:"150px", height:"50px", margin:"0px 5px", fontSize:"20px", fontFamily:"Quicksand", justifySelf:"center"}} onClick={()=>handleAcceptSettingClick()}>Accept</div>
            </div>

          </div>

        </div>
    )
  }
  else{
    return null;
  }

};

export default AnalyticsTracker;

export const pixelTrack = (type, data) =>{
  const cookieAllow = JSON.parse(localStorage.getItem("c_data"));;
  if(cookieAllow === null || cookieAllow === undefined)
    return;

  if(cookieAllow.fbpixel !== "allowed")
    return

  ReactPixel.track(type, data);
}
