import "../../css/GameRoom.css"

const GameCard = ({game, handleCardClick}) =>{

    const handleClick = (gameId) =>{
        handleCardClick(gameId);
    }

    const getGameCardBg = () =>{
        if(game.gameType === "jumper")
            return "/assets/games/gameroom/cards/jumper_card.png"
        else if(game.gameType == "platformer" || game.gameType == "platformer_xl")
            return "/assets/games/gameroom/cards/platformer_card.png"
        else if(game.gameType == "runner")
            return "/assets/games/gameroom/cards/runner_card.png"
        else if(game.gameType == "mazer")
            return "/assets/games/gameroom/cards/mazer_card.png"
        else if(game.gameType == "swimmer")
            return "/assets/games/gameroom/cards/swimmer_card.png"
        else if(game.gameType == "invader")
            return "/assets/games/gameroom/cards/invader_card.png"
        else if(game.gameType == "racer")
            return "/assets/games/gameroom/cards/racer_card.png"
        else if(game.gameType == "popper")
            return "/assets/games/gameroom/cards/popper_card.png"
    }

    return(
        <div className="game-card" onClick={()=>handleClick(game.userId + "_" + game.gameId)}>
            <div style={{position:"absolute", display:"flex", width:"90%", height:"35px", top:"10%", justifyContent:"center", alignItems:"center"}}>
                <div style={{textAlign:"center", overflowWrap:"break-word", lineHeight:"14px", width:"70%", fontSize:"14px"}}>{game.gameName}</div>
            </div>
            <img className="game-card-bg" src={getGameCardBg()} />
            <img className="game-card-icon" src={game.gameDesign.gameicon}/>

            <div style={{position:"absolute", width:"40%", height:"35px", top:"75%", left:"25px", display:"flex", alignItems:"center"}}>
                <div style={{width:"100%", textAlign:"left", fontSize:"12px", overflowWrap:"break-word", lineHeight:"12px", fontWeight:"600"}}>
                    {game.userName}
                </div>    
            </div>

        </div>
    )
}

export default GameCard;